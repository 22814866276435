import React, { useState } from "react";
import styled from "styled-components";
import launchImage from "../assets/images/back/spacex-launch.jpg";

const CoreValuesSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LeftPanel = styled.div`
  flex: 1;
  /* 이미지 경로를 절대경로로 수정 (public 폴더에 있을 경우) */
  background: url(${launchImage}) no-repeat center center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const RightPanel = styled.div`
  flex: 1;
  padding: 2rem;
  z-index: 1;

  @media (max-width: 768px) {
    position: relative;
    text-align: center;
    width: 90%;
  }
`;

const CoreValueItem = styled.div`
  margin-bottom: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #333;
`;

const CoreValueTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 1rem 0;
  transition: color 0.3s ease;

  &:hover {
    color: #ff4500;
  }
`;

const CoreValueContent = styled.div<{ isOpen: boolean }>`
  max-height: ${(props) => (props.isOpen ? "200px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 0.9rem;
  color: #aaa;
`;

const Icon = styled.div`
  font-size: 1.5rem;
`;

const CoreValues = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleOpen = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <CoreValuesSection>
      <LeftPanel />
      <RightPanel>
        <h2>OUR CORE VALUES</h2>
        <CoreValueItem onClick={() => toggleOpen(0)}>
          <CoreValueTitle>
            INNOVATION <Icon>{openIndex === 0 ? "−" : "+"}</Icon>
          </CoreValueTitle>
          <CoreValueContent isOpen={openIndex === 0}>
            <p>
              지속적인 기술 혁신을 통해 우주 탐사와 교육의 새로운 가능성을
              열어갑니다.
            </p>
          </CoreValueContent>
        </CoreValueItem>
        <CoreValueItem onClick={() => toggleOpen(1)}>
          <CoreValueTitle>
            EDUCATION <Icon>{openIndex === 1 ? "−" : "+"}</Icon>
          </CoreValueTitle>
          <CoreValueContent isOpen={openIndex === 1}>
            <p>
              실용적이고 창의적인 교육 프로그램을 제공하여 미래 인재를
              양성합니다.
            </p>
          </CoreValueContent>
        </CoreValueItem>
        <CoreValueItem onClick={() => toggleOpen(2)}>
          <CoreValueTitle>
            COOPERATION <Icon>{openIndex === 2 ? "−" : "+"}</Icon>
          </CoreValueTitle>
          <CoreValueContent isOpen={openIndex === 2}>
            <p>
              기업, 연구 기관, 정부 기관 및 국제 사회와의 협력을 통해 우주기술
              개발에 필요한 생태계를 구축합니다.
            </p>
          </CoreValueContent>
        </CoreValueItem>
        <CoreValueItem onClick={() => toggleOpen(3)}>
          <CoreValueTitle>
            SUSTAINABILITY <Icon>{openIndex === 3 ? "−" : "+"}</Icon>
          </CoreValueTitle>
          <CoreValueContent isOpen={openIndex === 3}>
            <p>
              자원의 효율적 사용, 우주 쓰레기 관리, 친환경 기술 개발을 통해 지속
              가능한 우주기술 개발 및 탐사를 추구합니다.
            </p>
          </CoreValueContent>
        </CoreValueItem>
        <CoreValueItem onClick={() => toggleOpen(4)}>
          <CoreValueTitle>
            SOCIAL RESPONSIBILITY <Icon>{openIndex === 4 ? "−" : "+"}</Icon>
          </CoreValueTitle>
          <CoreValueContent isOpen={openIndex === 4}>
            <p>
              교육 분야에 대한 사회적 책임을 다하며, 우주 기술의 혜택을 더 많은
              사람들이 누릴 수 있도록 노력합니다.
            </p>
          </CoreValueContent>
        </CoreValueItem>
      </RightPanel>
    </CoreValuesSection>
  );
};

export default CoreValues;
