import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import styled from "styled-components";

const Background = styled.div`
  background: url("/assets/Structure 2.jpg") no-repeat center center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayBox = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  display: flex;
  flex-direction: row;
  color: #fff;
  margin-top: 80px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// 왼쪽 회사 정보 섹션
const LeftInfo = styled.div`
  flex: 1;
  margin-right: 2rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const InfoItem = styled.div`
  margin-bottom: 1rem;
`;

const InfoTitle = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  font-weight: 600;
`;

const InfoText = styled.p`
  margin: 0;
  line-height: 1.4;
  font-size: 0.95rem;
`;

// 오른쪽 폼 섹션
const RightForm = styled.div`
  flex: 1;
`;

// 폼 스타일
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

// 폼 필드 묶음
const FormField = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

// 라벨
const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;

  & span {
    color: red;
    margin-left: 0.25rem;
  }
`;

// 인풋, 텍스트에어리어
const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #222; /* 어두운 배경 */
  color: #fff;

  &:focus {
    outline: none;
    border-color: #555;
  }
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 100px;
  resize: vertical;
  background-color: #222;
  color: #fff;

  &:focus {
    outline: none;
    border-color: #555;
  }
`;

// 버튼
const Button = styled.button`
  display: block;
  padding: 0.75rem;
  font-size: 1rem;
  background: linear-gradient(45deg, #141e30, #243b55);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 1rem auto 0;
  width: 100%;
  max-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.5s ease;

  &:hover {
    background: linear-gradient(45deg, #243b55, #141e30);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

// 동의 체크박스 섹션
const ConsentSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & input {
    margin-right: 0.5rem;
  }

  & ${Label} {
    font-weight: normal;
    margin-bottom: 0;
  }
`;

// 모달
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #111;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  max-width: 90%;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

const ContactForm: React.FC = () => {
  const [state, handleSubmit] = useForm("mqapzpgk"); // 실제 Formspree ID로 교체
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setShowModal(true);
    }
  }, [state.succeeded]);

  // 팝업 닫으면 새로고침
  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  return (
    <Background>
      <OverlayBox>
        {/* 왼쪽 회사 정보 */}
        {/* <LeftInfo>
          <InfoItem>
            <InfoTitle>Phone / 전화</InfoTitle>
            <InfoText>Coming Soon</InfoText>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Email / 이메일</InfoTitle>
            <InfoText>support@miraspace.ai</InfoText>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Address / 주소</InfoTitle>
            <InfoText>
              125, Wangsimni-ro, Seongdong-gu,
              <br />
              Seoul, Republic of Korea
            </InfoText>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Operating Hours</InfoTitle>
            <InfoText>
              Mon-Fri: 09:00 ~ 18:00
              <br />
              Sat-Sun: By Appointment
            </InfoText>
          </InfoItem>
          <InfoItem>
            <InfoTitle>Instagram</InfoTitle>
            <InfoText>@miraspace.ai</InfoText>
          </InfoItem>
        </LeftInfo> */}

        {/* 오른쪽 폼 */}
        <RightForm>
          <StyledForm onSubmit={handleSubmit}>
            {/* 소속 / Organization */}
            <FormField>
              <Label htmlFor="organization">
                Organization / 소속<span>*</span>
              </Label>
              <Input
                id="organization"
                type="text"
                name="organization"
                required
              />
              <ValidationError
                prefix="Organization"
                field="organization"
                errors={state.errors}
              />
            </FormField>

            {/* 이름 / Name */}
            <FormField>
              <Label htmlFor="name">
                Name / 이름<span>*</span>
              </Label>
              <Input id="name" type="text" name="name" required />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </FormField>

            {/* 이메일 / Email */}
            <FormField>
              <Label htmlFor="email">
                Email / 이메일<span>*</span>
              </Label>
              <Input id="email" type="email" name="email" required />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </FormField>

            {/* 국가 / Country */}
            <FormField>
              <Label htmlFor="country">
                Country / 국가<span>*</span>
              </Label>
              <Input id="country" type="text" name="country" required />
              <ValidationError
                prefix="Country"
                field="country"
                errors={state.errors}
              />
            </FormField>

            {/* 연락처 / Contact Number */}
            <FormField>
              <Label htmlFor="contact">Contact Number / 연락처</Label>
              <Input id="contact" type="text" name="contact" />
              <ValidationError
                prefix="Contact"
                field="contact"
                errors={state.errors}
              />
            </FormField>

            {/* 문의사항 / Inquiry */}
            <FormField>
              <Label htmlFor="message">Inquiry / 문의사항</Label>
              <TextArea id="message" name="message" />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </FormField>

            {/* 개인정보 수집 및 이용 동의 / Consent */}
            <ConsentSection>
              <Input id="consent" type="checkbox" name="consent" required />
              <Label htmlFor="consent">
                I agree to the collection and use of personal information /
                개인정보 수집 및 이용에 동의합니다.<span>*</span>
              </Label>
              <ValidationError
                prefix="Consent"
                field="consent"
                errors={state.errors}
              />
            </ConsentSection>

            <Button type="submit" disabled={state.submitting}>
              Go for Launch
            </Button>
          </StyledForm>
        </RightForm>
      </OverlayBox>

      {/* 제출 완료 모달 */}
      {showModal && (
        <Modal>
          <ModalContent>
            <p>Form submitted successfully! / 제출이 완료되었습니다!</p>
            <Button onClick={closeModal}>Close / 닫기</Button>
          </ModalContent>
        </Modal>
      )}
    </Background>
  );
};

export default ContactForm;
