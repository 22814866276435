import React from "react";
import styled from "styled-components";
// import { Link as RouterLink } from "react-router-dom";
// import Moon from "../assets/images/back/Moon2.jpg";
import LAUNCHPAD from "../assets/Products/MIRA-LAUNCHPAD.png";
import LIFTOFF from "../assets/Products/MIRA-LIFTOFF.png";
// import TickerText from "../components/TickerText";

const Missionsec = styled.div`
  margin-top: 60px;
`;

// const MissionHeaderSection = styled.section`
//   text-align: center;
//   padding: 40px 20px;
//   background-color: #000;
//   color: #fff;
//   position: relative;
// `;

// const MissionLabelImage = styled.img`
//   width: 100%;
//   max-width: 500px;
//   margin: 0 auto;
//   display: block;
// `;

// const MissionDescription = styled.p`
//   font-size: 1.2rem;
//   margin: 20px auto;
//   max-width: 800px;
//   line-height: 1.6;
// `;

// const StyledRouterButton = styled(RouterLink)`
//   display: block;
//   padding: 0.75rem;
//   font-size: 1rem;
//   background: linear-gradient(45deg, #141e30, #243b55);
//   color: #fff;
//   text-align: center;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
//   margin: 1rem auto;
//   width: 100%;
//   max-width: 200px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   text-decoration: none;
//   transition: background 0.5s ease;

//   &:hover {
//     background: linear-gradient(45deg, #243b55, #141e30);
//   }
// `;

const MissionListSection = styled.section`
  padding: 40px 20px;
  background-color: #111;
  color: #fff;
`;

const MissionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const MissionCard = styled.div`
  background: #222;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #333;
  transition: opacity 0.3s ease;
`;

const MissionCardImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const MissionCardImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const MissionTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const MissionSubtitle = styled.h4`
  font-size: 1rem;
  margin-bottom: 10px;
`;

// const MissionText = styled.p`
//   font-size: 1rem;
//   line-height: 1.4;
// `;

const missions = [
  {
    title: "MIRA - LAUNCHPAD",
    subtitle: "Mission 1: Pioneer",
    // description: "위성 기술과 우주 탐사의 기초 습득.",
    available: true,
    image: LAUNCHPAD,
  },
  {
    title: "MIRA - LIFTOFF",
    subtitle: "Mission 2: Innovate",
    // description: "Dive into advanced systems and innovative design techniques.",
    available: true,
    image: LIFTOFF,
  },
  // {
  //   title: "Mission 3: Collaborate",
  //   description: "Coming Soon",
  //   available: false,
  //   image: "",
  // },
  // {
  //   title: "Mission 4: Expand",
  //   description: "Coming Soon",
  //   available: false,
  //   image: "",
  // },
  // {
  //   title: "Mission 5: Evolve",
  //   description: "Coming Soon",
  //   available: false,
  //   image: "",
  // },
];

const MissionsPage: React.FC = () => {
  return (
    <Missionsec>
      {/* <MissionHeaderSection>
        <MissionLabelImage src={Moon} alt="Mission Label" />
        <MissionDescription>
          Discover our mission-driven approach to revolutionize space education.
          Our innovative programs empower you to explore satellite technology
          hands-on.
        </MissionDescription>
        <StyledRouterButton to="/products">Go for Launch</StyledRouterButton>
      </MissionHeaderSection> */}

      {/* <TickerText direction="left" /> */}
      <MissionListSection>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Our Missions
        </h2>
        <MissionGrid>
          {missions.map((mission, index) => (
            <MissionCard
              key={index}
              style={{ opacity: mission.available ? 1 : 0.5 }}
            >
              {mission.image && (
                <MissionCardImageWrapper>
                  <MissionCardImage
                    src={mission.image}
                    alt={`${mission.title} Image`}
                  />
                </MissionCardImageWrapper>
              )}
              <MissionTitle>{mission.title}</MissionTitle>
              <MissionSubtitle>{mission.subtitle}</MissionSubtitle>
              {/* <MissionText>{mission.description}</MissionText> */}
              {/* <StyledRouterButton to="/products">
                Go for Launch
              </StyledRouterButton> */}
            </MissionCard>
          ))}
        </MissionGrid>
      </MissionListSection>
      {/* <TickerText text="And Explore the Universe" direction="right" /> */}
    </Missionsec>
  );
};

export default MissionsPage;
