import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaRocket, FaSatellite, FaSpaceShuttle } from "react-icons/fa";
import MissionsPage from "./Mission";

const Container = styled.div`
  padding: 100px 40px;
  background: linear-gradient(135deg, #f6f8fd 0%, #f1f4f9 100%);
  min-height: 100vh;
  margin-top: 60px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: 3.2rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #2a2a2a 0%, #434343 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled(motion.p)`
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 4rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const ProgramGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
`;

const ProgramCard = styled(motion.div)`
  background: white;
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  }
`;

const ProgramIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #3498db;
  display: inline-block;
  padding: 1rem;
  background: rgba(52, 152, 219, 0.1);
  border-radius: 12px;
`;

const ProgramTitle = styled.h3`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const ProgramDescription = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const ProgramFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Feature = styled.li`
  color: #555;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  font-size: 0.95rem;

  &:before {
    content: "•";
    color: #3498db;
    font-weight: bold;
    margin-right: 0.5rem;
  }
`;

const LearnMore = styled.button`
  background: transparent;
  border: 2px solid #3498db;
  color: #3498db;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  margin-top: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #3498db;
    color: white;
  }
`;

const programs = [
  {
    icon: <FaRocket />,
    title: "Beginner Program",
    subtitle: "Understanding Space Basics",
    description: "우주 과학의 기초부터 시작하는 입문자를 위한 프로그램입니다.",
    features: [
      "우주 과학 기초 이론",
      "기본 위성 시스템 이해",
      "간단한 우주 미션 시뮬레이션",
      "팀 프로젝트 실습",
    ],
    duration: "8주 과정",
  },
  {
    icon: <FaSatellite />,
    title: "Intermediate Program",
    subtitle: "Hands-on Satellite Training",
    description: "실제 위성 데이터를 활용한 실무 중심의 교육 프로그램입니다.",
    features: [
      "실제 위성 데이터 분석",
      "위성 시스템 설계 실습",
      "지상국 운영 교육",
      "미션 계획 수립",
    ],
    duration: "12주 과정",
  },
  {
    icon: <FaSpaceShuttle />,
    title: "Advanced Program",
    subtitle: "Satellite Development & Operations",
    description: "위성 개발부터 운영까지 전문가 양성 과정입니다.",
    features: [
      "큐브위성 설계 및 제작",
      "우주 환경 시험",
      "위성 운영 시스템 개발",
      "실제 미션 참여",
    ],
    duration: "16주 과정",
  },
];

const EducationalPrograms: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <>
      <MissionsPage />
      <Container>
        <ContentWrapper>
          <Title
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Educational Programs
          </Title>
          <Subtitle
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.6 }}
          >
            위성 기술의 기초부터 실제 운영까지, 단계별 맞춤형 교육 프로그램을
            제공합니다.
          </Subtitle>

          <ProgramGrid
            as={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {programs.map((program, index) => (
              <ProgramCard key={index} variants={itemVariants}>
                <ProgramIcon>{program.icon}</ProgramIcon>
                <ProgramTitle>{program.title}</ProgramTitle>
                <ProgramDescription>{program.description}</ProgramDescription>
                <ProgramFeatures>
                  {program.features.map((feature, idx) => (
                    <Feature key={idx}>{feature}</Feature>
                  ))}
                </ProgramFeatures>
                <LearnMore>자세히 보기</LearnMore>
              </ProgramCard>
            ))}
          </ProgramGrid>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default EducationalPrograms;
