import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 50px;
  background-color: #007bff;
  text-align: center;
  color: white;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #007bff;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const CallToAction: React.FC = () => {
  return (
    <Container>
      <Title>Take the Next Step in Space Education</Title>
      <Button>Get Started</Button>
    </Container>
  );
};

export default CallToAction;
