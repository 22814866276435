import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

interface Testimonial {
  quote: string;
  author: string;
  position: string;
}

const Container = styled.div`
  padding: 120px 40px;
  background: #0a0a0a;
  color: #fff;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(25, 25, 25, 0.4) 0%,
      rgba(10, 10, 10, 0.8) 100%
    );
    pointer-events: none;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const Title = styled(motion.h2)`
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 2rem;
  color: #fff;

  span {
    display: block;
    font-size: 1.1rem;
    color: #888;
    margin-top: 1rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
`;

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 3rem;
  padding: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TestimonialCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.03);
  padding: 3rem;
  border-radius: 2px;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.4s ease;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 0;
    background: linear-gradient(to bottom, #3498db, #2ecc71);
    transition: height 0.4s ease;
  }

  &:hover {
    transform: translateX(10px);
    background: rgba(255, 255, 255, 0.05);

    &::before {
      height: 100%;
    }
  }
`;

const Quote = styled.div`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #e0e0e0;
  margin-bottom: 2.5rem;
  font-weight: 300;
  letter-spacing: 0.3px;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const AuthorImage = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 2px;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  &:hover::after {
    transform: translateX(100%);
  }
`;

const AuthorInfo = styled.div`
  h4 {
    font-size: 1.2rem;
    color: #fff;
    margin: 0;
    font-weight: 500;
  }

  p {
    font-size: 0.9rem;
    color: #888;
    margin: 0.4rem 0 0 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const testimonials: Testimonial[] = [
  {
    quote:
      "MiraSpace's innovative approach to satellite education has completely transformed our understanding of space technology. The hands-on experience was invaluable.",
    author: "Sarah Chen",
    position: "Aerospace Engineering Student",
  },
  {
    quote:
      "우주 교육의 새로운 패러다임을 제시했습니다. 학생들이 실제 위성 데이터를 다루며 우주 과학에 대한 깊은 이해와 흥미를 발견하는 모습이 인상적이었습니다.",
    author: "김민준",
    position: "항공우주학과 교수",
  },
  {
    quote:
      "The educational satellite kit is a masterpiece of STEM education. It perfectly bridges theoretical knowledge with practical application.",
    author: "Dr. Michael Roberts",
    position: "Education Director, Space Academy",
  },
  {
    quote:
      "위성 기술 교육의 혁신을 경험했습니다. 복잡한 우주 기술을 쉽게 이해하고 실습할 수 있는 교육 시스템이 매우 인상적이었습니다.",
    author: "이서연",
    position: "과학영재학교 교사",
  },
  {
    quote:
      "Revolutionary approach to space education. The program's ability to make complex satellite technology accessible to students is remarkable.",
    author: "Emma Thompson",
    position: "STEM Innovation Director",
  },
  {
    quote:
      "체계적인 커리큘럼과 실습 중심의 교육 방식이 탁월했습니다. 학생들의 우주 산업 진로 탐색에 실질적인 도움이 되었습니다.",
    author: "박준호",
    position: "우주기술연구소 선임연구원",
  },
];

const TestimonialsCaseStudies: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { x: -30, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <Container>
      <ContentWrapper>
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Voices of Innovation
          <span>Stories from Our Community</span>
        </Title>

        <TestimonialsGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} variants={itemVariants}>
              <Quote>{testimonial.quote}</Quote>
              <Author>
                <AuthorImage>{testimonial.author.charAt(0)}</AuthorImage>
                <AuthorInfo>
                  <h4>{testimonial.author}</h4>
                  <p>{testimonial.position}</p>
                </AuthorInfo>
              </Author>
            </TestimonialCard>
          ))}
        </TestimonialsGrid>
      </ContentWrapper>
    </Container>
  );
};

export default TestimonialsCaseStudies;
