import React from "react";
import styled from "styled-components";
import logo from "../assets/images/MIRA_LOGO_SYMBOL.png";
import Img1 from "../assets/images/Bottom.png";
// import WhySpaceEducation from "./WhySpaceEducation";
// import ImpactResearch from "./ImpactResearch";
import CallToAction from "./CallToAction";

import WhySpaceEducation from "./WhySpaceEducation";
import CoreValues from "./CoreValue";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  justify-content: center;
  color: white;
  padding: 80px 120px;
  min-height: 100vh;
  gap: 80px;

  @media (max-width: 768px) {
    padding: 40px 20px;
    gap: 40px;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(${Img1});
    background-size: cover;
    background-position: center;
  }
`;

const TextContainer = styled.div`
  max-width: 600px;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    border-radius: 15px;
    backdrop-filter: blur(10px);
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 30px;
  letter-spacing: -1px;

  @media (max-width: 768px) {
    font-size: 3.5rem;
  }
`;

const Paragraph = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 40px;
  word-break: keep-all;

  hr {
    width: 300px;
    margin: 40px auto;
    border: none;
    height: 1px;
    background: linear-gradient(90deg, transparent, white, transparent);
  }

  @media (max-width: 768px) {
    font-size: 14px;

    hr {
      width: 300px;
      margin: 30px auto;
    }
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 500px;
  opacity: 0;
  animation: slideIn 1s ease-in forwards 0.5s;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 10%;
  height: auto;
  margin-bottom: 40px;
  transition: transform 0.3s ease;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 140px;
    margin-bottom: 30px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    /* transform: translateY(-10px); */
  }
`;

const AboutUs: React.FC = () => {
  return (
    <>
      <Container>
        <TextContainer>
          <Title>About Us</Title>
          <StyledLogo src={logo} alt="Mira Space Logo" />
          <Paragraph>
            Mira Space is leading the future of satellite education through
            innovative satellite kits and hands-on training curricula.
            <br />
            <br /> Aiming to nurture the next generation of space professionals,
            Mira Space provides satellite technology education aligned with the
            New Space era, establishing a lasting heritage in space training.
            Through cutting-edge satellite kits and global partnerships, Mira
            Space is opening new horizons in space technology education and
            building a future full of greater possibilities.
            <br />
            <br />
            <hr />
            <br />
            미라스페이스는 혁신적인 위성 키트와 실습형 교육 커리큘럼을 통해
            <br /> 위성 교육의 미래를 선도하고 있습니다.
            <br />
            <br />
            미라스페이스는 차세대 우주 전문가 양성을 목표로,
            <br /> New Space 시대에 발맞춘 위성 기술 교육을 통해 헤리티지를
            제공합니다. <br />
            혁신적인 위성 키트와 글로벌 파트너십을 통해 우주 기술 교육의 새
            지평을 열어가며, <br /> 더 큰 가능성의 미래를 만들어갑니다.
          </Paragraph>
          <br />
        </TextContainer>
        <ImageContainer>
          <StyledImage src={Img1} alt="Mira Space Img" />
        </ImageContainer>
      </Container>

      <WhySpaceEducation />
      <CoreValues />
      {/* <WhySpaceEducation />
      <ImpactResearch /> */}
      <CallToAction />
    </>
  );
};

export default AboutUs;
