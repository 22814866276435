import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled.div`
  padding: 80px 20px;
  background: linear-gradient(180deg, #1a1a1a 0%, #0d0d0d 100%);
  color: #fff;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  color: #fff;
`;

const IntroText = styled(motion.p)`
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  color: #b8b8b8;
`;

const Section = styled(motion.div)`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BenefitCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const BenefitTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  color: #fff;
`;

const BenefitText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #ddd;
`;

const WhySatelliteEducation: React.FC = () => {
  const benefits = [
    {
      title: "실제 위성 기술 습득",
      description:
        "미라스페이스 교육을 통해 위성 설계부터 운용까지 최신 기술을 직접 배웁니다.",
    },
    {
      title: "혁신적 문제 해결",
      description:
        "실제 미션 모의실험으로 창의적 문제 해결 능력을 키워 미래를 준비합니다.",
    },
    {
      title: "글로벌 협력 경험",
      description:
        "국제 프로젝트와 네트워킹을 통해 다양한 문화와 협업 경험을 쌓습니다.",
    },
    {
      title: "미래 직업 역량 강화",
      description:
        "STEM 역량과 실무 경험을 바탕으로 우주 산업의 주역으로 성장할 수 있습니다.",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.3 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  return (
    <Container>
      <ContentWrapper>
        <Title
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Why Satellite Education?
        </Title>

        <IntroText
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.6 }}
        >
          미라스페이스는 첨단 위성 기술 교육을 통해 미래 우주 산업의 주역을
          양성합니다.
          <br />
          혁신적인 실습과 글로벌 협력을 경험하세요.
        </IntroText>

        <Section
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} variants={itemVariants}>
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <BenefitText>{benefit.description}</BenefitText>
            </BenefitCard>
          ))}
        </Section>
      </ContentWrapper>
    </Container>
  );
};

export default WhySatelliteEducation;
