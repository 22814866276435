import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaRocket, FaUsers, FaLightbulb } from "react-icons/fa";

const Container = styled.div`
  min-height: 100vh;
  padding: 100px 40px;
  background: linear-gradient(135deg, #f6f8fd 0%, #f1f4f9 100%);
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #2a2a2a 0%, #434343 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled(motion.p)`
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 4rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const CardsContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const Card = styled(motion.div)`
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  text-align: center;

  h3 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin: 1rem 0;
  }

  p {
    color: #666;
    line-height: 1.6;
  }
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: #3498db;
  margin-bottom: 1rem;
`;

const ApplySection = styled(motion.div)`
  background: white;
  padding: 4rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

const ApplyTitle = styled.h2`
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
`;

const ApplyDescription = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
  font-size: 1.1rem;
`;

const ApplyButton = styled.a`
  display: inline-block;
  background: #3498db;
  color: white;
  padding: 1rem 2.5rem;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(52, 152, 219, 0.3);
  }
`;

const JoinUs: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const handleApplyClick = () => {
    window.location.href =
      "mailto:support@miraspace.ai?subject=Join MiraSpace Team Application";
  };

  return (
    <Container>
      <ContentWrapper>
        <Title
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Join Our Team
        </Title>
        <Subtitle
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          우주 교육의 혁신을 함께 이끌어갈 열정적인 인재를 찾고 있습니다.
          MiraSpace와 함께 미래의 우주 산업을 이끌어갈 인재를 양성하세요.
        </Subtitle>

        <CardsContainer
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Card variants={itemVariants}>
            <Icon>
              <FaRocket />
            </Icon>
            <h3>Innovation Leaders</h3>
            <p>
              우주 교육의 새로운 패러다임을 제시할 혁신적인 리더를 찾습니다.
            </p>
          </Card>
          <Card variants={itemVariants}>
            <Icon>
              <FaUsers />
            </Icon>
            <h3>Education Experts</h3>
            <p>
              우주 과학 교육에 대한 전문성과 열정을 가진 교육 전문가를 모십니다.
            </p>
          </Card>
          <Card variants={itemVariants}>
            <Icon>
              <FaLightbulb />
            </Icon>
            <h3>Technical Advisors</h3>
            <p>
              우주 산업의 전문성을 바탕으로 자문해주실 기술 고문을 찾습니다.
            </p>
          </Card>
        </CardsContainer>

        <ApplySection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          <ApplyTitle>Ready to Join Us?</ApplyTitle>
          <ApplyDescription>
            MiraSpace와 함께 우주 교육의 미래를 만들어가고 싶으신가요?
            <br />
            지금 바로 지원하세요. 여러분의 열정과 전문성을 기다립니다.
          </ApplyDescription>
          <ApplyButton onClick={handleApplyClick}>Apply Now</ApplyButton>
        </ApplySection>
      </ContentWrapper>
    </Container>
  );
};

export default JoinUs;
