import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1c1c1c, #2d2d2d);
  color: #fff;
  text-align: center;
  padding: 0 20px;
  animation: ${fadeIn} 0.8s ease-out;
`;

const Title = styled.h1`
  font-size: 8rem;
  margin: 0;
  letter-spacing: 0.5rem;
  text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
  
  @media (max-width: 768px) {
    font-size: 5rem;
    letter-spacing: 0.3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #d3d3d3;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const HomeButton = styled.button`
  margin-top: 2rem;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    background: #fff;
    color: #000;
  }
`;

const NotFound: React.FC = () => {
  const handleHome = () => {
    window.location.href = "/";
  };

  return (
    <NotFoundContainer>
      <Title>404</Title>
      <Subtitle>The page you are looking for does not exist.</Subtitle>
      <HomeButton onClick={handleHome}>Go Home</HomeButton>
    </NotFoundContainer>
  );
};

export default NotFound;
